import React from "react";
import { Animated } from "react-animated-css";
import QRCode from "qrcode.react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import ButtonSalta, { IconButtonSalta } from "./ButtonSalta";
import { estilosProv } from "../../configProvincias";

const QRModalSalta = ({
  error,
  token,
  nroQR,
  solicitud,
  cargandoQR,
  clickGenerarQR,
  downloadImg,
}) => {
  const color = process.env.REACT_APP_MANIFEST_COLOR;
  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];

  return (
    <div
      style={{ position: "fixed", top: "20%", overflowY: "scroll" }}
      className="modal fade pb-5"
      id="qrModal"
      role="dialog"
      aria-labelledby="qrModalLabel"
      aria-hidden="true"
    >
      <DivModal className="modal-dialog" role="document">
        <div className="modal-content">
          <div
            style={{
              background: color,
              height: "13px",
              width: "100%",
              borderRadius: "5px",
            }}
          ></div>
          <div className="modal-body text-center mb-2 text-small">
            <button
              type="button"
              className="close"
              style={{ position: "absolute", top: "5px", right: "5px" }}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="text-center">
              <div
                id="download"
                className="pt-2 pb-2"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                {error === false && (
                  <div style={{ fontSize: ".8rem", color: "#A8A7A6" }}>
                    Válido por 24 horas
                  </div>
                )}
                <div className="mt-2">
                  {token !== "" && error === false && (
                    <Animated animationIn="zoomIn">
                      <QRCode className="mb-1" value={token} />
                      <div className="m-bold">
                        <h6 className="mb-0">{nroQR}</h6>
                      </div>
                    </Animated>
                  )}
                </div>

                {cargandoQR && (
                  <div>
                    <div className="animate-flicker">
                      <b>Cargando QR ...</b>
                    </div>
                    <br></br>
                  </div>
                )}

                {error === false && (
                  <div style={{ margin: "10px 0" }}>
                    <span
                      className="m-bold"
                      style={{ fontSize: "1rem", color: "#A8A7A6" }}
                    >
                      Autorizo a retirar de mi cuenta la suma de:
                    </span>
                    <h4 className="m-bold">
                      <NumberFormat
                        value={solicitud.importe}
                        displayType={"text"}
                        style={{color: stylesByOrg.success}}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </h4>
                    <p style={{ fontSize: "1rem" }}>En un punto de venta</p>
                  </div>
                )}
              </div>

              {error === false && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "35px",
                  }}
                >
                  <IconButtonSalta onClick={downloadImg}>
                    <span
                      className="iconify"
                      style={{ color: "#E8DF05" }}
                      data-icon="ic:baseline-download"
                      data-inline="false"
                      data-width="25px"
                    ></span>
                  </IconButtonSalta>

                  <ButtonSalta variant="success" data-dismiss="modal">
                    Volver
                  </ButtonSalta>
                </div>
              )}

              {error === true && (
                <div>
                  <h4>Ups. </h4>
                  <div className="text-center pb-2 mt-2">
                    Favor reintente más tarde. Verifique la conexión a internet.
                  </div>
                  <br></br>
                  <button className="btn btn-info" onClick={clickGenerarQR}>
                    Reintentar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </DivModal>
    </div>
  );
};

export default QRModalSalta;

const DivModal = styled.div`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
`;
